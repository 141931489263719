
.hero {
  color: $white;

  .container {
    background-repeat: no-repeat;
    background-position: center right 20%;
  }


  h2 {
    color: $white;
  }


  &-heading {

    p, a {
      font-size: 1.8rem;
    }

    p {
      [class^="icon-"] {
        font-size: 3.6rem;
      }
    }

  }


  &-buttons {
    margin-top: $spacer-large;

    [class*="button"] {
      width: 100%;
      //display: block;
      text-align: left;
      font-size: 2rem;
      font-weight: bold;
      text-decoration: none;
      padding: $spacer-small-plus $spacer-medium-plus;
      transition: transform .35s, background-color .25s;

      &:not(:first-child) {
        margin-top: $spacer-medium;
      }

      &:hover,
      &:active,
      &:focus {
        transform: translateX( -10px );
      }


    }

    .version {
      line-height: $line-height-s;
      margin-top: $spacer-medium-plus;
      font-size: 1.4rem;
    }

  }

}

@include respond-to("medium-up") {

  .hero {

    .container {
      display: flex;
      justify-content: space-between;
    }

    &-buttons {
      flex-shrink: 0;
      margin-left: $spacer-extra-large-plus;
      margin-top: 0;

      [class*="button"] {
        box-shadow: $box-shadow1;
      }
    }

  }

}
