
.carousel {

  margin-top: $spacer-medium;

  a {
    display: block;
    text-decoration: none;
    font-weight: bold;

    &:hover,
    &:active,
    &:focus {
      img {
        border-color: $table-border;
      }
    }

  }

  img {
    border: 1px solid $tabs-border;
    padding: $spacer-tiny-plus;
    background-color: $white;
    transition: border-color .25s;
  }

  span {
    display: block;
    margin-top: $spacer-small-plus;
  }

  .single-shot {
    text-align: center;
  }

}

@include respond-to("tiny") {
  .single-shot {
    &:not(:last-child) {
      margin-bottom: $spacer-medium;
    }
  }
}
