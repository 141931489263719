

/*
 * Colors variant
 * parse variables "$layout-colors" to generate
 * each color layout variant
 */


// Mixin for Bretzel, KNACSS & pépin
.layout {
  @each $name, $color, $gradient, $bgimg in $layout-colors {
    &--#{$name} {

      .bg {
        &--primary {
          background-color: $color;
        }
        &--gradient {
          background: $gradient;
        }
      }

      b { color: $color; }
      .title-underlined::after { background-color: $color; }
      .color--primary,
      h4, .h4-like { color: $color; }

      .hero .container {
        background-image: url(../img/#{$bgimg});
      }

      .link {

        &:not([class*="button"]) {
          color: $color;

          &:hover,
          &:active,
          &:focus {
            color: darken( $color, 10% );
          }
        }

        &[class*="button"] {

          text-decoration: none;

          &:hover,
          &:active,
          &:focus {
            color: inherit;
            text-decoration: none;
          }
        }
      } // end .link

      .button {

        &--ghost {

          &:hover,
          &:active,
          &:focus {
            background-color: $color;
          }

        }

      } // end .button

    }
  }
}


// Specific layout styles
.layout--knacss,
.layout--pepin {
  .bloc-box .bloc-step {
    font-size: 4.2rem;
    color: $gray-600;
  }
}

.layout--knacss .bloc-box .bloc-step {
  font-size: 4.2rem;
}

.layout--pepin .bloc-box .bloc-step {
  font-size: 5.4rem;
}






/*
 * Text, formating & decoration
 */

.title-underlined {
  text-align: center;

  &::after {
    content: "";
    display: block;
    width: 80px;
    height: 2px;
    margin: $spacer-medium auto 0;
  }
}

.display-n {
 display: none;
}


.link {
  text-decoration: underline;
  transition: color .25s;

  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}


.text-icon {

  display: flex;
  align-items: center;

  img,
  svg,
  [class^="icon-"] {
    margin-right: $spacer-small;
    flex-shrink: 0;
  }

  &--center {
    @extend .text-icon;

    justify-content: center;
  }

}


pre {
  background-color: $gray-100;
  border: 1px solid $gray-400;
  padding: $spacer-medium;
  margin-bottom: $spacer-medium;
  color: $gray-800;
  font-size: 1.5rem;
  line-height: 1.4;
  overflow: auto;
}


.table {

  border: none;

  td,
  th {
    padding: $spacer-small $spacer-small-plus;
    border: 1px solid $gray-300;
  }

}


@include respond-to("small") {

  .layout--pepin {

    table {

      td,
      th {
        width: auto;
      }

      thead {
        position: absolute;
        clip: rect(1px, 1px, 1px, 1px);
        overflow: hidden;
        height: 1px;
        width: 1px;
      }

      tr {

        &:not(:first-child) {
          border: none;
          border-top: 1px solid $gray-300;
        }

        td {
          border: none;

          &:nth-child(n+3) {
            border-top: 1px solid $gray-300;
          }

          &:first-child {
            width: 30% !important;
            vertical-align: top;
            border-right: 1px solid $gray-300;
          }

          &:not(:first-child) {
            display: block !important;
          }

          &:nth-of-type(2):before {
            content: "Description: ";
          }
          &:nth-of-type(3):before {
            content: "Statut: ";
          }
          &:nth-of-type(4):before {
            content: "Doc: ";
          }

        }
      }
    }

  }

}




/*
 * Containers
 */


.container {

  margin-left: auto;
  margin-right: auto;

  @include respond-to("small-up") {
    padding: $spacer-large-plus $spacer-medium-plus;
  }

  @include respond-to("large-up") {
    padding: $spacer-extra-large $spacer-large;
  }

  max-width: $extra-large;
  padding: $spacer-large $spacer-medium;

}


// Containers max-width
.mw {

  margin-left: auto;
  margin-right: auto;

  &--s {
    @extend .mw;
    max-width: 610px
  }
  &--m {
    @extend .mw;
    max-width: 860px
  }
  &--l {
    //@extend .mw;
    //max-width: 1460px;
  }
  &--xl {
    //@extend .mw;
    //max-width: 1460px;
  }
  &--xxl {
    @extend .mw;
    max-width: 1460px;
  }
}




/*
 * Layout
 */

.bg {

  &--gray {
    background-color: $gray-lightest;

    .bloc-box {
      .bloc-step {
        background-color: $white;
      }
    }
  }

  &--dark {
    background-color: $gray-650;
  }

  &--darker {
    background-color: $gray-700;
  }

  &--gradient,
  &--primary,
  &--dark,
  &--darker {
    color: $white;

    b { color: white; }

    .link {

      &:not([class*="button"]) {
        color: $white;
        text-decoration: underline;
        transition: color .25s;

        &:hover,
        &:active,
        &:focus {
          color: rgba( 255, 255, 255, .8 );
        }
      }

    }

  }

}
//end .bg

.text-details {
  display: block;
  font-size: 1.4rem;
  font-style: italic;
  font-family: serif;
  margin-top: $spacer-tiny;
}

.button {

  &--l {
    padding: $spacer-tiny-plus $spacer-small-plus;

    @include respond-to("small-up") {
      padding: $spacer-small-plus $spacer-medium-plus;
    }
  }

  &--ghost {

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: $white;
      box-shadow: none;
    }

  }

}


.bloc-box {

  border: 1px solid $gray-500;
  border-radius: $border-radius;
  padding: $spacer-small-plus;

  p {
    font-size: 1.6rem;
  }

  .bloc-step {
    width: 70px;
    height: 70px;
    background-color: $gray-lightest;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $gray-700;
    margin: 0 auto $spacer-medium;
    font-size: 3.6rem;
    border-radius: 50%;
  }

  > *:last-child {
    margin-bottom: 0;
  }

}

@include respond-to("tiny") {
  .bloc-box {
    &:not(:first-child) {
      margin-top: $spacer-medium;
    }
  }
}

@include respond-to("small-up") {
  .bloc-box {
    padding: $spacer-medium-plus;
  }
}




/*
 * Animation
 */
@include respond-to("small-up") {

  .animated {
    animation-name: contentSlideUp;
  }

  .animate {
    transform: translateY(20px);
    opacity: 0;
    animation-duration: .6s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }

  .anim-tiny-delay {
    animation-delay: .3s;
  }

  .anim-small-delay {
    animation-delay: .7s;
  }

}



@keyframes contentSlideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}


@keyframes spaceboots {
  0% {
    transform:translate(2px,1px) rotate(0)
  }
  10% {
    transform:translate(-1px,-2px) rotate(-1deg)
  }
  20% {
    transform:translate(-3px,0) rotate(1deg)
  }
  30% {
    transform:translate(0,2px) rotate(0)
  }
  40% {
    transform:translate(1px,-1px) rotate(1deg)
  }
  50% {
    transform:translate(-1px,2px) rotate(-1deg)
  }
  60% {
    transform:translate(-3px,1px) rotate(0)
  }
  70% {
    transform:translate(2px,1px) rotate(-1deg)
  }
  80% {
    transform:translate(-1px,-1px) rotate(1deg)
  }
  90% {
    transform:translate(2px,2px) rotate(0)
  }
  100% {
    transform:translate(1px,-2px) rotate(-1deg)
  }
}
