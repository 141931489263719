/*!
* www.KNACSS.com v7.0.3 (march, 1 2018) @author: Alsacreations, Raphael Goetter
* Licence WTFPL http://www.wtfpl.net/
*/

/* ----------------------------- */
/* ==Table Of Content            */
/* ----------------------------- */

/*
1- Reboot (basic reset)
2- Libraries  :
  - Base
  - Print
  - Layout (alignment, modules, positionning)
  - Utilities (width and spacers helpers)
  - Responsive helpers
  - WordPress reset (disabled by default)
  - Grillade (Grid System)
3- Components :
  - Media object
  - Skip Links for accessibility
  - Tables
  - Forms
  - Buttons
  - Checkbox
  - Tabs
  - Tags
  - Badges
  - Alerts
*/

@import "./node_modules/knacss/sass/_vendor/_reboot"; // Bootstrap reboot (basic reset)

// WARNING : you should comment the following @import (variables)
// and move variables file from knacss folder to your own project folder!
// @import "_config/_variables.scss"; (bretzel: already imported by bretzel in styles.scss, before knacss.scss)

// @import "_config/_mixins.scss"; (bretzel: already imported by bretzel in styles.scss, before knacss.scss)

// Core libraries
@import "./node_modules/knacss/sass/_library/_base.scss"; // basic styles
@import "./node_modules/knacss/sass/_library/_print.scss"; // print quick reset
@import "./node_modules/knacss/sass/_library/_layout.scss"; // alignment, modules, positionning
@import "./node_modules/knacss/sass/_library/_utilities.scss"; // width and spacers helpers
@import "./node_modules/knacss/sass/_library/_responsive.scss"; // Responsive Web Design helpers
// @import "_library/_wordpress.scss"; // WordPress reset and basic styles

// New Grid System by default (Grid Layout). If you prefer old "Flexbox" Grid System, replace file with "_library/grillade-flex.scss"
// Note that none of these file is prefixed by an underscore, in order to compile them.
@import "./node_modules/knacss/sass/_library/grillade-grid.scss"; // grid system with Grid Layout - Commented in bretzel

// Components
@import "./node_modules/knacss/sass/components/_media.scss"; // media object
@import "./node_modules/knacss/sass/components/_skip-links.scss"; // skip links
@import "./node_modules/knacss/sass/components/_tables.scss"; // data tables consistency
@import "./node_modules/knacss/sass/components/_forms.scss"; // forms consistency and styles
@import "./node_modules/knacss/sass/components/_buttons.scss"; // buttons styles
@import "./node_modules/knacss/sass/components/_checkbox.scss"; // checkbox, radio, switch styles
// @import "./node_modules/knacss/sass/components/_tabs.scss"; // tabs styles
//@import "./node_modules/knacss/sass/components/_arrows.scss"; // arrows styles
//@import "./node_modules/knacss/sass/components/_tags.scss"; // tags styles
//@import "./node_modules/knacss/sass/components/_badges.scss"; // badges styles
//@import "./node_modules/knacss/sass/components/_alerts.scss"; // alerts styles
